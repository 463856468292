import OktaCallback from '@/components/auth/OktaCallback.vue'

import UnhealthyConsultsView from '@/views/UnhealthyConsultsView.vue'
import LoginView from '@/views/LoginView.vue'

import CliniciansView from '@/views/CliniciansView.vue'
import ClinicianView from '@/views/ClinicianView.vue'

import ConsultsView from '@/views/ConsultsView.vue'
import ConsultView from '@/views/ConsultView.vue'

import ConsultRateView from '@/views/ConsultRateView.vue'

import ConsultRateTierView from '@/views/ConsultRateTierView.vue'

import CollabsView from '@/views/Collabs/CollabsView.vue'
import StateCollabRequirementView from '@/views/Collabs/StateCollabRequirementView.vue'
import CollabAgreementView from '@/views/Collabs/CollabAgreementView.vue'
import PerformanceView from '@/views/PerformanceView.vue'
import ActivityRateView from '@/views/ActivityRateView.vue'

import ClientsView from '@/views/ClientsView.vue'
import ClientView from '@/views/ClientView.vue'
import HomeView from '@/views/HomeView.vue'
import PaymentsAndTransfersView from '@/views/PaymentsAndTransfersView.vue'
import ForecastView from '@/views/ForecastView.vue'

import { authGuard } from './utils'
import ConfigValuesView from '@/views/ConfigValuesView.vue'

export const CLINICIAN_ROUTE = 'Clinician'
export const VIEW_OPPORTUNITY_ROUTE = 'ViewOpportunity'
export const EDIT_OPPORTUNITY_ROUTE = 'EditOpportunity'

export const VIEW_FEEDBACK_ROUTE = 'ViewFeedback'
export const EDIT_FEEDBACK_ROUTE = 'EditFeedback'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/unhealthy',
    name: 'UnhealthyConsults',
    component: UnhealthyConsultsView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/clinicians',
    name: 'Clinicians',
    component: CliniciansView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/clinicians/:id/:tab?',
    name: CLINICIAN_ROUTE,
    component: ClinicianView,
    props: (route) => ({
      clinicianId: route.params.id
    }),
    meta: {
      queryParamNavigation: true, // We want to track query params in datadog views
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/consults',
    name: 'Consults',
    component: ConsultsView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/consults/:id',
    name: 'Consult',
    component: ConsultView,
    props: (route) => ({ consultId: route.params.id }),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    component: () =>
      import(
        /* webpackChunkName: "SearchOpportunities" */ '@/views/Opportunities/Search.vue'
      ),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/performance',
    name: 'Performance',
    component: PerformanceView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/opportunities/create',
    name: 'CreateOpportunity',
    component: () =>
      import(
        /* webpackChunkName: "EditOpportunity" */ '@/views/Opportunities/Edit.vue'
      ),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/opportunities/:id',
    props: (route) => ({ opportunityId: route.params.id }),
    component: () =>
      import(
        /* webpackChunkName: "OpportunityView" */ '@/views/Opportunities/Opportunity.vue'
      ),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: VIEW_OPPORTUNITY_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "ViewOpportunity" */ '@/views/Opportunities/View.vue'
          )
      },
      {
        path: 'edit',
        name: EDIT_OPPORTUNITY_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "EditOpportunity" */ '@/views/Opportunities/Edit.vue'
          )
      }
    ]
  },
  {
    path: '/clients',
    name: 'Clients',
    component: ClientsView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/clients/:id/demand',
    redirect: '/clients/:id/forecast'
  },
  {
    path: '/clients/:id/:tab?',
    name: 'Client',
    component: ClientView,
    props: (route) => ({
      clientId: route.params.id,
      tab: route.params.tab
    }),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/consult-rates/:id/demand',
    redirect: '/consult-rates/:id/forecast'
  },
  {
    path: '/consult-rates/:id/:tab?',
    name: 'ConsultRate',
    component: ConsultRateView,
    props: (route) => ({
      consultRateId: route.params.id,
      tab: route.params.tab
    }),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/consult-rate-tiers/:id/:tab?',
    name: 'ConsultRateTier',
    component: ConsultRateTierView,
    props: (route) => ({
      consultRateTierId: route.params.id,
      tab: route.params.tab
    }),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/activity-rates/:id/:tab?',
    name: 'ActivityRate',
    component: ActivityRateView,
    props: (route) => ({
      activityRateId: route.params.id,
      tab: route.params.tab
    }),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/demand',
    redirect: '/forecast'
  },
  {
    path: '/forecast',
    name: 'Forecast',
    component: ForecastView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/payments-and-transfers/:tab?',
    name: 'Payments & Transfers',
    component: PaymentsAndTransfersView,
    props: (route) => ({
      tab: route.params.tab
    }),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/collabs/:tab?',
    name: 'Collab Agreements',
    component: CollabsView,
    props: (route) => ({
      tab: route.params.tab
    }),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/collabs/agreements/:id',
    name: 'Collab Agreement',
    component: CollabAgreementView,
    props: (route) => ({
      collabId: route.params.id
    }),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/collabs/:tab?/:state',
    name: 'StateCollabRequirement',
    component: StateCollabRequirementView,
    props: (route) => ({ state: route.params.state, tab: route.params.tab }),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () =>
      import(
        /* webpackChunkName: "FeedbackSearchView" */ '@/views/Feedback/Search.vue'
      ),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/feedback/create',
    name: 'FeedbackCreate',
    component: () =>
      import(
        /* webpackChunkName: "FeedbackEditView" */ '@/views/Feedback/Edit.vue'
      ),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/feedback/:id',
    component: () =>
      import(
        /* webpackChunkName: "FeedbackView" */ '@/views/Feedback/Feedback.vue'
      ),
    props: (route) => ({
      feedbackId: route.params.id
    }),
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: VIEW_FEEDBACK_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "FeedbackDetailView" */ '@/views/Feedback/View.vue'
          )
      },
      {
        path: 'edit',
        name: EDIT_FEEDBACK_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: "FeedbackCreateView" */ '@/views/Feedback/Edit.vue'
          )
      }
    ]
  },
  {
    path: '/config-values',
    name: 'Configuration Values',
    component: ConfigValuesView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: authGuard
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/auth0Login',
    component: () => import('@/components/auth/Auth0Callback.vue')
  },
  {
    path: '/implicit/callback',
    component: OktaCallback
  },
  {
    path: '/*',
    component: () => import(/* webpackChunkName: "error" */ '../views/404.vue')
  }
]

export default routes
