<template>
  <div>
    <profile-form
      v-slot="formAttrs"
      class="mt-6"
      header="Account Status"
      :action-function="updateClinician"
      @submit="onSubmit"
    >
      <profile-field v-model="clinicianLocal.status" v-bind="formAttrs">
        <v-select
          v-model="clinicianLocal.status"
          label="Clinician Account Status"
          outlined
          :items="clinicianStatus"
          name="status"
        />
        <template #view>
          <data-row
            label="Clinician account status"
            class="text-capitalize"
            :value="clinicianLocal.status"
          />
        </template>
      </profile-field>
    </profile-form>
    <profile-form
      v-slot="formAttrs"
      class="mt-6"
      header="Medallion"
      :action-function="updateClinician"
      @submit="onSubmit"
    >
      <profile-field v-model="clinicianLocal.medallion_id" v-bind="formAttrs">
        <template #default="fieldAttrs">
          <v-text-field
            v-bind="fieldAttrs"
            name="medallion_id"
            :rules="medallionRules"
            placeholder="Medallion ID"
          />
        </template>
        <template #view>
          <data-row label="Medallion ID" :value="clinicianLocal.medallion_id" />
        </template>
      </profile-field>
      <profile-field
        :value="clinicianLocal.credentialing_status"
        v-bind="formAttrs"
      >
        <template #default="fieldAttrs">
          <v-text-field
            label="Credentialing Status"
            v-bind="fieldAttrs"
            name="credentialing_status"
            disabled
          />
        </template>
        <template #view>
          <data-row
            label="Credentialing Status"
            class="text-capitalize"
            :value="
              formatCredentialingStatus(clinicianLocal.credentialing_status)
            "
          />
        </template>
      </profile-field>
      <profile-field :value="clinicianLocal.npi" v-bind="formAttrs">
        <template #default="fieldAttrs">
          <v-text-field
            label="NPI"
            type="number"
            v-bind="fieldAttrs"
            name="npi"
            :rules="npiRules"
            clearable
          />
        </template>
        <template #view>
          <data-row label="NPI" :value="clinicianLocal.npi" />
        </template>
      </profile-field>
      <profile-field :value="clinicianLocal.dea_number" v-bind="formAttrs">
        <template #default="fieldAttrs">
          <v-text-field
            label="DEA Number"
            v-bind="fieldAttrs"
            name="dea_number"
            clearable
          />
        </template>
        <template #view>
          <data-row label="DEA Number" :value="clinicianLocal.dea_number" />
        </template>
      </profile-field>
      <profile-field :value="clinicianLocal.dea_x_number" v-bind="formAttrs">
        <template #default="fieldAttrs">
          <v-text-field
            label="DEA-X Number"
            v-bind="fieldAttrs"
            name="dea_x_number"
            clearable
          />
        </template>
        <template #view>
          <data-row label="DEA-X Number" :value="clinicianLocal.dea_x_number" />
        </template>
      </profile-field>
      <profile-field
        :value="clinicianLocal.medallion_last_sync"
        v-bind="formAttrs"
      >
        <template #default="fieldAttrs">
          <v-text-field
            label="Last Sync"
            v-bind="fieldAttrs"
            name="last_sync"
            disabled
          />
        </template>
        <template #view>
          <data-row label="Last Sync">
            {{ formatLastSync(clinicianLocal.medallion_last_sync) }}</data-row
          >
          <div v-if="clinicianLocal.medallion_id" class="text-right">
            <v-tooltip left max-width="400" color="black">
              <template #activator="{ on }">
                <v-btn
                  class="primary ml-auto"
                  rounded
                  :disabled="syncingMedallion"
                  v-on="on"
                  @click="syncMedallion"
                >
                  Resync
                </v-btn>
              </template>
              <strong class="text-h6">Warning</strong>
              <p>This will manually sync all existing licenses in Medallion.</p>
              <p>
                It should only be used in the rare case Medallion is having
                issues completing verification for a license.
              </p>
              <p>If in doubt, please reach out to the credentialing team.</p>
            </v-tooltip>
            <v-alert v-if="syncError" class="mt-4" type="error" outlined>
              {{ syncError }}
            </v-alert>
          </div>
        </template>
      </profile-field>
    </profile-form>
    <profile-licenses
      class="mt-6"
      :clinician.sync="clinicianLocal"
      data-testid="profile-licenses"
      hide-add
      disable-edit
    />
    <profile-payer-enrollments
      class="mt-6"
      :clinician.sync="clinicianLocal"
      data-testid="profile-payer-enrollments"
      hide-add
      disable-edit
    />
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow.vue'
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import ProfileLicenses from '@/components/clinicians/profile/ProfileLicenses.vue'
import ProfilePayerEnrollments from '@/components/clinicians/profile/ProfilePayerEnrollments.vue'
import { validNpi } from '@/utils/npi'
import { formatDate } from '@/utils/Date'
import { convertToTitleCase } from '@/utils/ConvertToTitleCase'

const v4uuid =
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

export default {
  name: 'ClinicianCredentialing',
  components: {
    ProfileLicenses,
    ProfilePayerEnrollments,
    ProfileForm,
    ProfileField,
    DataRow
  },
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      clinicianStatus: [
        { text: 'Active', value: 'active' },
        { text: 'Suspended', value: 'suspended' }
      ],
      medallionRules: [
        (v) => !v || v4uuid.test(v) || 'Medallion ID must be a valid v4 UUID'
      ],
      syncError: null,
      syncingMedallion: false,
      npiRules: validNpi
    }
  },
  computed: {
    clinicianLocal: {
      get: function () {
        return this.clinician
      },
      set: function (value) {
        return this.$emit('update:clinician', value)
      }
    }
  },
  methods: {
    async updateClinician(data) {
      try {
        const { result } = await this.$clinicians.update(
          this.clinician.id,
          data
        )
        return result
      } catch (e) {
        let message = 'Unable to update clinician. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      }
    },
    onSubmit(data) {
      const payload = { ...this.clinician, ...data }
      this.$emit('update:clinician', payload)
    },
    async syncMedallion() {
      this.syncingMedallion = true
      this.syncError = null
      try {
        await this.$clinicians.syncMedallion(this.clinician.id)
      } catch (e) {
        this.syncError = `Unable to sync licenses. ${e}`
      } finally {
        this.syncingMedallion = false
      }
    },
    formatLastSync(dateString) {
      if (!dateString) return ''
      return formatDate(dateString, 'MM/DD/YY HH:MM')
    },

    formatCredentialingStatus(status) {
      if (!status) {
        return ''
      }

      return convertToTitleCase(status)
    }
  }
}
</script>

<style lang="scss" scoped></style>
